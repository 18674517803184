
.PP_Block {
    height: auto;
}
.PrivacyPolicy_Block_Grid {
    padding-top: 8rem;
    ;
    padding-left: 100px;
    padding-right: 90px;
}
.PrivacyPolicy_Block {
    margin-top: 110px;
    width: 100%;
    ;
}

.PrivacyPolicy_Block_MH {
    padding-top: 40px;
    font-size: 1.8rem;
    color: var(--colorR);
}

.PrivacyPolicy_Block_Grid_BoldText {
    font-weight: bold;
}
.PP_Grid_Link {
    text-decoration: none;
    transition: transform 2sec;
}
.PP_Grid_Link:hover {
    color: var(--colorR);
}
.PrivacyPolicy_Block_P {
    font-size: 1rem;
    line-height: 35px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.PrivacyPolicy_Block_P1 {
    font-size: 1rem;
    line-height: 35px;
}
.PrivacyPolicy_Block_Grid_UL {
    padding-left: 60px;
}


.PrivacyPolicy_Block_P3 {
    font-weight: lighter;
}

@media only screen and (max-width: 900px) {
    .PP_Block {
        /* height: 2200px; */
    }
    .PrivacyPolicy_Block_Grid {
        /* height: 2100px; */
    }
  }

  @media only screen and (max-width: 800px) {
    .PP_Block {
        /* height: 2400px; */
    }
    .PrivacyPolicy_Block_Grid {
        /* height: 2300px; */
        padding-left: 70px;
        padding-right: 70px;
    }
  }

  @media only screen and (max-width: 700px) {
    .PP_Block {
        /* height: 2600px; */
    }
    .PrivacyPolicy_Block_Grid {
        /* height: 2500px; */
        padding-left: 50px;
        padding-right: 50px;
    }
  }

  @media only screen and (max-width: 600px) {
   
    .PrivacyPolicy_Block_Grid {
        /* height: 2700px; */
        padding-left: 30px;
        padding-right: 30px;
    }
  }
  @media only screen and (max-width: 530px) {

    .PrivacyPolicy_Block_Grid {
        padding-top: 4vh !important;
        /* height: 3100px; */
        padding-left: 10px;
        padding-right: 10px;
    }
  }
 
 

  