.img_bg{
    width: 100% !important;
    height: 70vh !important;
    border: 1px none #48a3b8 !important;
    background-position: 40% 0 !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-attachment: scroll !important;
    /* justify-content: center !important; */
    align-items: center ;
    display: flex !important;
    margin-top: 70px;
    margin-bottom: 0;
}
.text_bg_img{
    font-size: 4rem !important;
    /* font-weight: 600  !important; */
    color:black;
    padding: 10px 20px;
    font-family:  '__Poppins_c20e38', '__Poppins_Fallback_c20e38', sans-serif !important;;
}
.chip_scan{
    color: white !important;
    background-color: #48a3b8 !important;
    width: 100%;
    font-size: 1.2rem !important;
    padding: 22px 0px !important;
    font-family:  '__Poppins_c20e38', '__Poppins_Fallback_c20e38', sans-serif !important;;
cursor: pointer !important;
}
.txt_input{
    border-color: black !important;
    border-radius: 14px !important;
    width: 95%;
    min-height: 50px;
    /* margin-bottom: 20px !important; */
}
.div_multi{
    width: 103px;
    height: 103px;
    display: flex;
    align-items: center;
    border-radius: 50%;
    justify-content: center;
    background-color: #f4e3e2;
}
.img_multi{
    width: 64px;
    height: 64px;
}
.head_multi{
    color: #48a3b8;
    display: block;
    font-size: 1.25rem !important;
    margin-top: 15px !important;
    font-weight: 600 !important;
    line-height: 30px;
    min-height: 60px;
}
.sub_head_multi{
    font-size: 0.9375rem !important;
    font-weight: 400 !important;
    line-height: 22.5px;
    min-height: 44px;

}

@media (max-width:450px) {
    .text_bg_img{
        padding: 20px 10px;
        font-size: 1.5rem !important;
    }
    .img_bg{
        width: 100% !important;
        height: auto !important;
       
    }
    .txt_input {
             margin-bottom: 20px !important;
    }
}