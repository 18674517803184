.mainHead-th{
    font-size: 2rem !important;
    text-align: center;
    padding-bottom: 2vh !important;
    color:#48a3b8;

}
.subHead-th{
    font-size: 3.2rem !important;
    text-align: center;
    color:#48a3b8;
}