.ppr_cat{
height: 150px;
width: 230px;
cursor: pointer;
background-color: #48a3b8 !important;
color: white !important;
margin: 1vh 0px !important;
}
.txt_cat{
    align-content: center;
    height: 100%;
    display: grid;
    text-align: center;
}
.chip_new{
    background-color: #48a3b8 !important;
    color: #ffffff !important;
    cursor: pointer !important;
font-weight: 600;
}
.img_game{
    height: 100px;
    border-radius: 8px;
    border: 2px solid #eee;
    padding: 5px;
}

.img_game1{

    height: 40vh;
    border-radius: 8px;
    border: 2px solid #eee;
    padding: 5px;
    object-fit: contain;
}


.img_game2{
   height: 150px;
    border-radius: 8px;
    border: 2px solid #eee;
    padding: 5px;
}
.completed{
  font-size: 1.3rem !important;
  color: green;
  /* padding: 5px; */

}
.Dialog_CButton .MuiPaper-root{
    margin:0px;
  }
  .Dialog_CButton .MuiDialogContent-root{
    width: 330px;
          height:260px;
          text-align: center;
  }
  
  .Dialog_CButton .MuiBackdrop-root{
    background: rgba(0,0,0,0.44);
  
  }
  .img_comp{
    height: 100px;
    width: 100px;
    border-radius: 50%;
    object-fit: contain;
    border: 2px solid #eee;
    padding: 5px;
  }
  .img_comp1{
    height: 100px;
    width: 150px;
    object-fit: contain;
    border: 2px solid #eee;
    padding: 5px;
  }
  .blog_description p{
    margin-block: 0px !important;
    margin-inline: 0px !important;

  }