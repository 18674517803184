body {
  margin: 0;
  font-family: '__Poppins_c20e38', '__Poppins_Fallback_c20e38', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*{
  font-family: '__Poppins_c20e38', '__Poppins_Fallback_c20e38', sans-serif !important;
 
}
.fa, .far, .fas {
  font-family: "Font Awesome 5 Free" !important;

cursor:  pointer !important;
}
code {
  font-family: '__Poppins_c20e38', '__Poppins_Fallback_c20e38', sans-serif !important;
}
::-webkit-scrollbar{
  width: 0;
}